<template>
<div>
    <Spin />
</div>
</template>

<script>
import Spin from '../components/dashboard/SpinAndWin.vue'
export default {
    components: {
        Spin
    }
}
</script>


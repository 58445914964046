<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
        <Footer slot="footer" />
        <div slot="content">
            <!-- <input type="text" v-model="filters.name.value"> -->
            <div class="container-fluid">
                <div>
                    <div class="profile-div pb-4">

                    </div>
                </div>

                <div class="position-el-2">
                    <!-- <img src="../../assets/images/wint.png" height="30" class="mb-2" v-if="firstPhase">
                    <img src="../../assets/images/tap3.png" height="30" v-if="lastStage">
                    <img src="../../assets/images/tap2.png" height="30" v-if="loader">
                    <img src="../../assets/images/tap.png" height="50" @click="getNumber" v-if="firstPhase" style="cursor:pointer">
                  -->
                    <div class="centered" v-if="loader">
                        <div class="blob-1"></div>
                        <div class="blob-2"></div>
                    </div>
                    <button v-if="lastStage" class="mt-3" style="background: linear-gradient(180deg, #D2ECFF 0%, #A5D9FF 100%);border: 1px solid #B7E1FF;box-sizing: border-box;
border-radius: 5px; width:300px; height:50px;color:#fff; text-shadow: 0px 0px 30px #0094FF">{{ giftItem+ ' ' +'AIRTIME' }}</button>
                </div>

            </div>
        </div>
    </Wrapper>
</div>
</template>

<script>
import Navbar from '../partials/Navbar'
import Sidebar from '../partials/Sidebar'
import Footer from '../partials/Footer'
import Wrapper from '../partials/Wrapper'
import trans from "../../../translations";


import {
    mapState
} from "vuex";
import PictureInput from 'vue-picture-input'

export default {

    data() {

        return {
            tran: {},
            giftItem: '',
            loader: false,
            firstPhase:true,
            lastStage: false

        }
    },

    methods: {

        getNumber() {

            this.loader = true
 this.firstPhase = false
            setTimeout(() => {
                this.loader = false
this.lastStage = true
                this.$confetti.start();
                let giftArray = ["N50", "N200", "N100", "N200", "N100", "N1000", "N100", "500", "N200", "N50", "N200", "N100", "N50", "N100", "N200"]

                this.giftItem = giftArray[Math.floor(Math.random() * giftArray.length)];

            }, 10000)

        },

        sendGuestTosignUp() {
            this.$router.push("/signup");
        },

        stopConfetti(){
  this.$confetti.stop();

        }

    },
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
   
    },

    computed: {
        ...mapState({

            accountOverview: state => state.market.accountOverview,

        }),

        returnBankSelected() {

            return this.bankSelected
        },
        returnFirstName() {

            return this.firstName
        },
        returnLastName() {

            return this.lastName
        },
    },
    mounted() {

        this.tran = trans;

        let heading = {

            main: this.tran.profile,
        }

        this.$store.commit('market/savePageHeading', heading)
        this.$store.dispatch('auth/getBankList');

    }

}
</script>

<style scoped>
.centered {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(-10px) contrast(-20);
}

.blob-1,
.blob-2 {
    width: 30px;
    height: 30px;
    position: absolute;
    background:  #FFB100;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blob-1 {
    left: 20%;
    animation: osc-l 2.5s ease infinite;
}

.blob-2 {
    left: 80%;
    animation: osc-r 2.5s ease infinite;
    background: radial-gradient(97.36% 94.38% at 9% 7.13%, #4DFF79 0%, #0094FF 68.22%);
}

@keyframes osc-l {
    0% {
        left: 20%;
    }

    50% {
        left: 50%;
    }

    100% {
        left: 20%;
    }
}

@keyframes osc-r {
    0% {
        left: 80%;
    }

    50% {
        left: 50%;
    }

    100% {
        left: 80%;
    }
}
</style>
